// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
Rails.start()
ActiveStorage.start()

require('select2')
require("uikit")
require("@nathanvda/cocoon")
require("libs/datepicker/picker")
require("libs/datepicker/picker.date")
require("libs/datepicker/picker.time")
require("libs/datepicker/picker.it")
require("trix")
require("@rails/actiontext")


window.Rails = Rails
window.$ = $
window.UIkit = UIkit

import CookiesEuBanner from 'cookies-eu-banner'
import 'cookies-eu-banner/css/cookies-eu-banner.default.css'

document.addEventListener('DOMContentLoaded', () => {
  new CookiesEuBanner(function () {
       // Your code to launch when user accept cookies
   }, true);
})

$( document ).ready(function() {
  $('.select2').select2();
  $('.select2-multiple').select2();

  $(document).on("select2:open", () => {
    document.querySelector(".select2-container--open .select2-search__field").focus()
  })

  $('.datepicker').pickadate( {
    format: 'mmmm dd, yyyy',
    formatSubmit: 'yyyy-mm-dd',
    hiddenName: true,
    selectYears: true,
    selectMonths: true,
    klass: {
      selectYear: 'picker__select--year',
      selectMonth: 'picker__select--month'
    }
  });

  $('.timepicker').pickatime({
    // Escape any “rule” characters with an exclamation mark (!).
    format: 'HH:i',
    formatSubmit: 'HH:i',
    hiddenName: true,
    interval: 15,
  });

});

require("trix")
require("@rails/actiontext")
